import i18next from "i18n";
import Api from "utilities/api";

/**
 * ファイルアップロードを行う
 */
export default class FileUploader {
  /**
   * アップロードをする
   * @param {File} file ファイルデータ
   * @param {string} url リクエスト先URL
   * @param {object} form リクエストパラメーター
   * @param {number} retryNumber リトライ回数 未使用
   */
  upload(file, url = "attachments", form = {}, retryNumber = 0) {
    const formData = new FormData();
    formData.append("file", file);
    Object.keys(form).forEach((key) => formData.append(key, form[key]));

    // TODO: retryする
    return Api.fetch(url, {
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).catch(this.handleError);
  }

  /**
   * エラー処理
   */
  handleError(error) {
    return Promise.reject(
      error.response?.data?.message ||
        i18next.t("commons.errors.communicationError"),
    );
  }
}
