import PropTypes from "prop-types";
import React, { Component } from "react";
import Tag from "./Tag";

export default class TagSelector extends Component {
  constructor(props) {
    super(props);

    this.renderTag = this.renderTag.bind(this);
  }

  renderTag(tag, key) {
    const tagWrapperStyle = {
      cursor: "pointer",
    };

    const containerStyle = {
      margin: "4px 2px",
      ...this.props.containerStyle,
    };

    return (
      <div
        key={`tag-${key}`}
        style={tagWrapperStyle}
        onClick={this.props.onClickTag.bind(null, tag, !tag.selected)}
      >
        <Tag
          containerStyle={containerStyle}
          name={tag.name}
          color={tag.color}
          disabled={!tag.selected}
          isBorderForWhite={this.props.isBorderForWhite}
          tagStyle={this.props.tagStyle}
        />
      </div>
    );
  }

  render() {
    const style = {
      display: "flex",
      flexWrap: "wrap",
    };

    return (
      <div className="tag-list" style={style}>
        {this.props.tags.map(this.renderTag)}
      </div>
    );
  }
}

TagSelector.defaultProps = {
  tags: [],
  onClickTag() {},
  containerStyle: {},
};

TagSelector.prpTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ),
  onClickTag: PropTypes.func,
  yiq: PropTypes.number,
  isBorderForWhite: PropTypes.bool,
  tagStyle: PropTypes.object,
  containerStyle: PropTypes.object,
};
